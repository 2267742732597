<template>
  <div>
    <div class="body-box">
      <div style="width: 200px;float:left;">
        <el-menu
            :default-active="selectName"
            class="el-menu-vertical-demo"
            style="text-align: center"
            @select="handleOpen">
          <el-menu-item index="1">
            <span slot="title">大棚管理</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="agricul_right ib">
        <div v-if="selectName == 1">
          <div class="head-name-title" style="margin-left: 20px">大棚列表</div>
          <div style="margin:20px 0 30px 20px ">
            <el-button type="primary" @click="hideDialog" v-if="this.$getPermission('/pc/gHouse/save')">添加大棚</el-button>
            <el-dialog
                title=""
                :visible.sync="dialogVisible"
                width="600px" height="374px" style="">
              <addgreenhouse :optionList="optionList" :changeList="changeList" :userList="userList"
                             @rowList="addtabList"
                             @rowChangeList="changeListRow"></addgreenhouse>
            </el-dialog>
          </div>
          <el-table
              :data="tableData"
              :stripe="true"
              border
              style="width: 950px;margin-bottom: 30px;margin-left: 20px"
              :row-class-name="tableRowClassName">
            <el-table-column
                prop="id"
                label="序号">
            </el-table-column>
            <el-table-column
                prop="name"
                label="大棚名称">
            </el-table-column>

            <el-table-column
                prop="area"
                label="面积">
            </el-table-column>
            <el-table-column
                prop="crop"
                label="种植产品">
            </el-table-column>
            <el-table-column
                prop="status"
                :formatter="setStatusName"
                label="是否展示">
            </el-table-column>
            <el-table-column
                prop="state"
                :formatter="setStateName"
                label="状态">
            </el-table-column>
<!--            <el-table-column-->
<!--                prop="admin"-->
<!--                label="区块管理员">-->
<!--            </el-table-column>-->
            <el-table-column
                label="操作">
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleEdit(scope.$index, scope.row)">编辑
                </el-button>
                <el-popconfirm style="margin-left: 10px"
                               title="确定删除吗？"
                               @confirm="handleDelete(scope.$index, scope.row)"
                >
                  <el-button
                      size="mini"
                      type="text"
                      icon="el-icon-delete"
                      slot="reference"
                  >删除
                  </el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              style="margin-bottom: 20px;margin-left: 20px"
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="pageIndex"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              :total="totalPage"
              layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>

        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>


<script>
import addgreenhouse from '@/components/addgreenhouse'

export default {
  inject: [
    'reload'
  ],
  components: {
    addgreenhouse
  },
  data() {
    return {
      selectName: localStorage.getItem('selectIndex'),
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      tableData: [],
      dialogVisible: false,
      optionList: [],
      value1: '',
      changeList: {},
      userList: {},
    }
  },
  computed:{

  },
  mounted() {
    this.getDataList()
    this.getSelectList()

  },
  methods: {
    setStatusName(row){
      return row.status === 0 ? '开启' : '关闭'
    },
    setStateName(row){
      return row.state === 0 ? '正常' : '异常'
    },
    hideDialog() {
      this.dialogVisible = true
      this.changeList = {}
    },
    getDataList() {
      this.$api.post('gHouse/getList',
          {
            "page": this.pageIndex,
            "size": this.pageSize,
          }, res => {
            console.log(res, '123')
            this.tableData = res.data.records
            this.totalPage = res.data.total
          });
    },
    getSelectList() {
      this.$api.post('prod/getPros', {pId: '0'}, res => {
        console.log(res, '123options')
        this.optionList = res.data
      })
      this.$api.post('user/select', {}, res => {
        console.log(res, 'userList')
        this.userList = res.data
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      this.selectName = key
    },
    changeListRow(data) {  //修改替换
      console.log(data)
      let idx = this.tableData.findIndex((item) => item.id === data.id)
      console.log(idx)
      this.tableData[idx] = data
      this.dialogVisible = false
    },
    addClickGreenHouse() {  //添加大棚按钮
      this.dialogVisible = true
      this.changeList = {}
    },
    handleEdit(index, row) {  // 修改按钮
      console.log(index, row.id);
      if (this.$getPermission('/pc/gHouse/update')) {
        this.changeList = []
        this.dialogVisible = true
        let idx = this.tableData.findIndex((item) => item.id === row.id)
        console.log(idx, 'idx')
        this.changeList = this.tableData[idx]
        console.log(this.changeList, 'this.changeList')
      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }
    },
    handleDelete(index, row) {  //删除按钮
      console.log(index, row,);
      if (this.$getPermission('/pc/gHouse/delById')) {
        this.$api.post('gHouse/delById', {id: row.id}, res => {
          console.log(res, '删除', this.tableData)
          this.reload()
        })
      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }
    },
    addtabList(data) {  //子组件静默添加列表数据
      console.log(data)
      this.tableData.push(data)
      this.dialogVisible = false
    }
  },

}
</script>

<style scoped>
.body-box {
  margin-top: 20px;
}

.ib {
  /*display: inline-block;*/
}

.agricul_right {
  margin-left: 10px;
  width: 990px;

  background: #FFFFFF;
  float: left;
}

.clear {
  clear: both
}

.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}
</style>
