<template>
  <div style="height: 540px;width: 400px;text-align: center;margin-left: 80px">
    <div class="dialog-title">{{ buttonText }}大棚</div>
    <el-form :label-position="labelPosition" label-width="80px">
      <el-form-item label="大棚名称">
        <el-input v-model="name"></el-input>
      </el-form-item>
      <el-form-item label="面积">
        <el-input v-model="area"></el-input>
      </el-form-item>
      <!--      <el-form-item label="管理员">-->
      <!--        <el-select v-model="userId" placeholder="请选择" style="width: 320px;" @change="getSelectUser">-->
      <!--          <el-option-->
      <!--              v-for="item in userList"-->
      <!--              :key="item.userId"-->
      <!--              :label="item.username"-->
      <!--              :value="item.userId">-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <el-form-item label="种植产品">
        <el-select v-model="value1" placeholder="请选择" style="width: 140px;" @change="getOneSelect">
          <el-option
              v-for="item in optionList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="value2" placeholder="请选择" style="width: 140px;margin-left: 20px">
          <el-option
              v-for="item in optionList2"
              :key="item.id"
              :label="item.name"
              :value="item.name">
          </el-option>
        </el-select>

      </el-form-item>
      <el-form-item label="负责人">
        <el-select v-model="userId" placeholder="请选择" style="width: 320px;">
          <el-option
              v-for="item in userList"
              :key="item.userId"
              :label="item.username"
              :value="item.userId">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="是否展示">
        <el-select v-model="status" placeholder="请选择" style="width: 320px;">
          <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="state" placeholder="请选择" style="width: 320px;">
          <el-option
              v-for="item in stateList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-col>
      <el-button class="dialog-button" @click="updateGreenHouse">{{ buttonText }}</el-button>
    </el-col>
  </div>
</template>

<script>
export default {
  inject: [
    'reload'
  ],
  name: "addgreenhouse",
  data() {
    return {
      labelPosition: 'right',
      name: '',
      area: '',
      product: '',
      optionList2: [],
      value1: '',
      value2: '',
      id: '',
      userId: '',
      status: '',
      state: '',
      buttonText: '添加',
      newchangeList: [],
      statusList: [
        {
          id: 0,
          name: '开启'
        }, {
          id: 1,
          name: '关闭'
        }
      ],
      stateList: [
        {
          id: 0,
          name: '正常'
        }, {
          id: 1,
          name: '异常'
        }
      ]
    }
  },
  props: {
    optionList: {
      type: Array,
    },
    changeList: {
      type: Object,
    },
    userList: {
      type: Array,
    }
  },
  created() {
    this.newchangeList = JSON.parse(JSON.stringify(this.changeList))
  },
  mounted() {
    if (this.changeList.name) {
      this.name = this.changeList.name
      this.area = this.changeList.area
      this.value2 = this.changeList.crop
      this.id = this.changeList.id
      this.userId = this.changeList.userId
      this.status = this.changeList.status
      this.state = this.changeList.state
      this.buttonText = '修改'
    } else {
      this.name = ''
      this.area = ''
      this.value2 = ''
      this.id = ''
      this.userId = ''
      this.status = ''
      this.state = ''
      this.buttonText = '添加'
    }
  },
  methods: {
    getOneSelect(data) {
      console.log(data, '第一个框选中的值')
      this.$api.post('prod/getPros', {pId: data}, res => {
        console.log(res, '123options')
        this.optionList2 = res.data
      })
    },
    updateGreenHouse() {
      if (this.id !== '') {//修改
        let params = {
          name: this.name,
          num: this.name,
          area: this.area,
          crop: this.value2,
          id: this.id,
          userId: this.userId,
          status: this.status,
          state: this.state
        }
        console.log(params)
        this.$api.post('gHouse/update', params, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            console.log('修改')
            this.reload()
          }
        })
      } else {//添加
        let params = {
          name: this.name,
          num: this.name,
          area: this.area,
          crop: this.value2,
          userId: this.userId,
          status: this.status,
          state: this.state
        }
        console.log(params)
        this.$api.post('gHouse/save', params, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            console.log('添加成功')
            this.reload()
          }
        })
      }

    }
  },
  watch: {
    changeList: {
      handler: function (val) {
        if (this.changeList.name) {
          this.name = val.name
          this.area = val.area
          this.value2 = val.crop
          this.id = val.id
          this.status = val.status
          this.userId = val.userId
          this.state = val.state
          this.buttonText = '修改'
        } else {
          this.name = ''
          this.area = ''
          this.value2 = ''
          this.userId = ''
          this.id = ''
          this.buttonText = '添加'
        }
      }
    }
  }

}
</script>

<style scoped>
.dialog-title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}

.dialog-button {
  height: 60px;
  background: #009400;
  border-radius: 30px;
  width: 400px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
</style>
